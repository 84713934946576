<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="generatePaymentLinkForm"
        ref="generatePaymentLinkForm"
        @submit.prevent="submitPaymentLinkForm"
      >
        <v-row class="pt-4">
          <v-col cols="12">
            <ForwardDocument
              :item="params"
              required
              @update="updateContacts"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="generatePaymentLinkForm"
        v-shortkey="{ enter: ['enter'] }"
        @shortkey.native="submitPaymentLinkForm"
      >
        Wyślij link
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import ForwardDocument from '../../components/Forms/Partials/ForwardDocument'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper,
    ForwardDocument,
  },
  mixins: [afterFormSubmitted],
  data: () => ({
    params: {
      contactIds: [],
      externalContactEmails: [],
      orderIds: [],
    }
  }),
  computed: {
    ...mapState({
      selectedItems: state => state.orders.selectedItems,
      isProcessing: state => state.orders.isProcessing,
      orders: state => state.orders.items,
      order: state => state.layout.dialog.item,
      dialog: state => state.layout.dialog.data,
    }),
    selectedOrders() {
      return this.orders.filter(order => this.selectedItems.includes(order.id))
    },
  },
  created() {
    this.params.orderIds = this.selectedItems.length ? [...this.selectedItems] : [this.order.id]
    const { payer } = this.selectedOrders[0] || this.order
    this.getClient(payer.id)
  },
  methods: {
    ...mapActions({
      getClient: 'client/getSingleClient',
      sendPaymentLink: 'orders/sendPaymentLink',
      toggleMultiselectStatus: 'orders/toggleMultiselectStatus',
    }),
    updateContacts({ contactIds, externalContactEmails }) {
      this.params.contactIds = contactIds
      this.params.externalContactEmails = externalContactEmails
    },
    submitPaymentLinkForm() {
      if (this.$refs.generatePaymentLinkForm.validate()) {
        const { mode } = this.dialog

        this.sendPaymentLink({ ...this.params, mode })
          .then(() => {
            this.afterFormSubmitted('Przesłano link do płatności')
            this.toggleMultiselectStatus(false)
          })
      }
    },
  }
}
</script>
